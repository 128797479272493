<div class="sidebar">
    <div class="icons">
        <ng-container *ngFor="let menuItem of menuItens">
            <em
                *ngIf="menuItem.active"
                nz-icon
                [nzType]="menuItem.icon"
                nzTheme="outline"
                nz-tooltip
                [nzTooltipTitle]="menuItem.label"
                [routerLink]="menuItem.route"></em>
        </ng-container>
    </div>
</div>
<div class="navbar">
    <a class="logo">
        <img src="assets/logo/logo.png" alt="Linx" routerLink="/" />
    </a>
    <div class="wrapper">
        <div class="left-wrapper">
            <p class="mrAuto title main-title">DOME BACKOFFICE</p>
            <div class="user-wrapper"></div>
        </div>
        <div class="right-wrapper">
            <div class="navLink" (click)="logOut()">
                <em nz-icon nzType="logout" nzTheme="outline"></em>
                <p class="nav-link-text">Sair</p>
            </div>
        </div>
    </div>
</div>
<div class="content">
    <router-outlet></router-outlet>
</div>
