import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { IUser } from 'src/app/interfaces/authentication/user.interface';
import { IUserLoginPayload } from 'src/app/interfaces/authentication/user-login-payload.interface';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public user: Observable<IUser | null>;
    private userSubject: BehaviorSubject<IUser | null>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<IUser | null>(null);
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): IUser | null {
        return this.userSubject.value;
    }

    public login(payload: IUserLoginPayload) {
        return this.http.post<IUser>(`${environment.baseUrl}/backoffice/autenticacao/logar`, payload, { withCredentials: true }).pipe(
            map((user: IUser) => {
                return this.setStorageAndUserVariables(user);
            })
        );
    }

    public logout() {
        this.stopRefreshTokenTimer();
        localStorage.clear();
        sessionStorage.clear();
        this.userSubject.next(null);
        this.router.navigate(['/login']);
    }

    public refreshToken() {
        return of('mock').pipe(
            map(() => {
                const user: IUser = JSON.parse(localStorage.getItem('mockRefreshToken') as string);
                if (user) {
                    return this.setStorageAndUserVariables(user);
                } else {
                    this.logout();
                    return null;
                }
            })
        );
    }

    public updateUser(user: IUser) {
        this.userSubject.next(user);
        localStorage.setItem('mockRefreshToken', JSON.stringify(user));
    }

    public setStorageAndUserVariables(user: IUser): IUser {
        this.userSubject.next({
            ...user.registro,
            ...this.decodeToken(user.registro.token),
        });
        localStorage.setItem('token', user.registro.token);
        localStorage.setItem('idUsuario', user.registro.id);
        localStorage.setItem('refreshToken', user.registro.token);
        localStorage.setItem('mockRefreshToken', JSON.stringify(user));
        this.startRefreshTokenTimer();
        return user;
    }

    public getToken() {
        return localStorage.getItem('token');
    }

    public isLoggedIn() {
        return this.userValue && this.getToken();
    }

    private refreshTokenTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {}, 0);

    private decodeToken(token: string) {
        return JSON.parse(window.atob(token.split('.')[1]));
    }

    public hasRole(role: string) {
        if (!role) {
            return true;
        }

        return this.userValue?.roles?.includes(role) || this.userValue?.profile === 'ADMIN';
    }

    private startRefreshTokenTimer() {
        const jwtToken = this.decodeToken(this.userValue!.token);
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - 60 * 1000;
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}
