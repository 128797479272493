import { Component } from '@angular/core';
import { menuItens } from 'src/app/constants/menu-itens.const';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
    selector: 'app-dome',
    templateUrl: './dome.component.html',
    styleUrls: ['./dome.component.scss'],
})
export class DomeComponent {
    public menuItens = menuItens.filter((item) => this.authService.hasRole(item.role));

    constructor(private authService: AuthenticationService) {}

    logOut() {
        this.authService.logout();
    }
}
