import { Notify } from 'notiflix';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'backoffice-dome';

  constructor() {
    Notify.init({ plainText: false, messageMaxLength: 9999, width: '360px' })
  }
}
