<div class="container-fluid page-login">
  <div class="row">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-xs-3 col-md-3 col-sm-12 bg-primary colum-height colum-height-a d-flex align-items-center">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h1 class="text-brand-linx" title="Linx"> Linx </h1>
                <h2 class="text-brand-product text-uppercase text-truncate" title="Template" style="line-height: 3rem;">
                  Dome Backoffice </h2>
              </div>
              <p class="copyright"> &copy; Linx - Todos direitos reservados </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="colum-height colum-height-b d-flex align-items-center">
            <div class="row">
              <div class="col-12">
                <picture class="center-align logotipo text-center" style="display: block;">
                  <source srcset="assets/logo/sm-logo-mob.png" media="(max-width: 768px)">
                  <source srcset="assets/logo/lg-logo.png">
                  <img srcet="sm-logo.png" alt="Logo Linx" />
                </picture>
              </div>
              <div class="col-12">
                <router-outlet></router-outlet>
              </div>
            </div>
            <a href="#!" class="btn btn-link link-support" title="Suporte"> Suporte </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>