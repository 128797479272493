export enum Roles {
    HOME_ACESSAR = 'HOME_ACESSAR',
    USUARIOS_ACESSAR = 'USUARIOS_ACESSAR',
    USUARIOS_LISTAR = 'USUARIOS_LISTAR',
    USUARIOS_CRIAR = 'USUARIOS_CRIAR',
    USUARIOS_EDITAR = 'USUARIOS_EDITAR',
    USUARIOS_EXCLUIR = 'USUARIOS_EXCLUIR',
    USUARIOSERVICO_LISTAR = 'USUARIOSERVICO_LISTAR',
    USUARIOSERVICO_INCLUIR = 'USUARIOSERVICO_INCLUIR',
    USUARIOSERVICO_EDITAR = 'USUARIOSERVICO_EDITAR',
    USUARIOSERVICO_EXCLUIR = 'USUARIOSERVICO_EXCLUIR',
    USUARIOSERVICO_SUBSTITUIRCHAVEACESSO = 'USUARIOSERVICO_SUBSTITUIRCHAVEACESSO',
    USUARIOS_VINCULAR_PERFIL = 'USUARIOS_VINCULAR_PERFIL',
    EMPRESAS_ACESSAR = 'EMPRESAS_ACESSAR',
    EMPRESAS_LISTAR = 'EMPRESAS_LISTAR',
    EMPRESAS_EXCLUIR = 'EMPRESAS_EXCLUIR',
    EMPRESAS_EDITAR = 'EMPRESAS_EDITAR',
    EMPRESAS_CRIAR = 'EMPRESAS_CRIAR',
    EMPRESAS_DETALHES = 'EMPRESAS_DETALHES',
    CERTIFICADO_EDITAR = 'CERTIFICADO_EDITAR',
    CERTIFICADO_VINCULAR = 'CERTIFICADO_VINCULAR',
    EMAILS_ACESSAR = 'EMAILS_ACESSAR',
    EMAILS_LISTAR = 'EMAILS_LISTAR',
    PERFIL_LISTAR = 'PERFIL_LISTAR',
    PERFIL_INCLUIR = 'PERFIL_INCLUIR',
    PERFIL_EDITAR = 'PERFIL_EDITAR',
    PERFIL_EXCLUIR = 'PERFIL_EXCLUIR',
    PERFIL_OBTER = 'PERFIL_OBTER',
    ACAOBACKOFFICE_ACESSAR = 'ACAOBACKOFFICE_ACESSAR',
    ACAOBACKOFFICE_LISTAR = 'ACAOBACKOFFICE_LISTAR',
    ACAOBACKOFFICE_INCLUIR = 'ACAOBACKOFFICE_INCLUIR',
    ACAOBACKOFFICE_EDITAR = 'ACAOBACKOFFICE_EDITAR',
    ACAOBACKOFFICE_EXCLUIR = 'ACAOBACKOFFICE_EXCLUIR',
    ACAOBACKOFFICE_OBTER = 'ACAOBACKOFFICE_OBTER',
    PLANOS_LISTAR = 'PLANOS_LISTAR',
    PLANOS_INCLUIR = 'PLANOS_INCLUIR',
    PLANOS_EDITAR = 'PLANOS_EDITAR',
    PLANOS_EXCLUIR = 'PLANOS_EXCLUIR',
    PLANOS_OBTER = 'PLANOS_OBTER',
    MODULOS_LISTAR = 'MODULOS_LISTAR',
    MODULOS_INCLUIR = 'MODULOS_INCLUIR',
    MODULOS_EDITAR = 'MODULOS_EDITAR',
    MODULOS_EXCLUIR = 'MODULOS_EXCLUIR',
    MODULOS_OBTER = 'MODULOS_OBTER',
    ACAO_ACESSAR = 'ACAO_ACESSAR',
    ACAO_LISTAR = 'ACAO_LISTAR',
    ACAO_INCLUIR = 'ACAO_INCLUIR',
    ACAO_EDITAR = 'ACAO_EDITAR',
    ACAO_EXCLUIR = 'ACAO_EXCLUIR',
    ACAO_OBTER = 'ACAO_OBTER',
    REPROCESSAMENTO_ACESSAR = 'REPROCESSAMENTO_ACESSAR',
    REPROCESSAMENTO_LISTAR = 'REPROCESSAMENTO_LISTAR',
    REPROCESSAMENTO_REPROCESSAR = 'REPROCESSAMENTO_REPROCESSAR',
    FUNCIONALIDADES_ACESSAR = 'FUNCIONALIDADES_ACESSAR',
    FUNCIONALIDADES_LISTAR = 'FUNCIONALIDADES_LISTAR',
    FUNCIONALIDADES_EDITAR = 'FUNCIONALIDADES_EDITAR',
}
