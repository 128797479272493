import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DomeComponent } from './layouts/dome/dome.component';
import { AuthenticationComponent } from './layouts/authentication/authentication.component';
import { AuthGuard } from './guards/auth.guard';
import { Roles } from './enums/roles.enum';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },

    {
        path: '',
        component: DomeComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'home',
                loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
                data: { role: Roles.HOME_ACESSAR },
            },
            {
                path: 'usuarios',
                canActivate: [AuthGuard],
                loadChildren: () => import('./pages/gerenciar-usuarios/gerenciar-usuarios.module').then((m) => m.GerenciarUsuariosModule),
                data: { role: Roles.USUARIOS_LISTAR },
            },
            {
                path: 'permissionamento',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/gerenciamento-permissoes/gerenciamento-permissoes.module').then((m) => m.GerenciamentoPermissoesModule),
                data: { role: Roles.ACAO_LISTAR },
            },
            {
                path: 'emails',
                canActivate: [AuthGuard],
                loadChildren: () => import('./pages/gerenciar-emails/gerenciar-emails.module').then((m) => m.GerenciarEmailsModule),
                data: { role: Roles.EMAILS_LISTAR },
            },
            {
                path: 'empresas',
                canActivate: [AuthGuard],
                loadChildren: () => import('./pages/gerenciar-empresas/gerenciar-empresas.module').then((m) => m.GerenciarEmpresasModule),
                data: { role: Roles.EMPRESAS_LISTAR },
            },
            {
                path: 'reprocessamento',
                canActivate: [AuthGuard],
                loadChildren: () => import('./pages/reprocessamento/reprocessamento.module').then((m) => m.ReprocessamentoModule),
                data: { role: Roles.REPROCESSAMENTO_LISTAR },
            },
            {
                path: 'permissionamento-backoffice',
                canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/permissionamento-backoffice/permissionamento-backoffice.module').then(
                        (m) => m.PermissionamentoBackofficeModule
                    ),
                data: { role: Roles.ACAOBACKOFFICE_LISTAR },
            },
            {
                path: 'funcionalidades',
                canActivate: [AuthGuard],
                loadChildren: () => import('./pages/funcionalidades/funcionalidades.module').then((m) => m.FuncionalidadesModule),
                data: { role: Roles.FUNCIONALIDADES_LISTAR },
            },
        ],
    },
    {
        path: '',
        component: AuthenticationComponent,
        children: [
            { path: 'login', loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule) },
            {
                path: 'alterar-senha',
                loadChildren: () =>
                    import('./pages/forcar-alterar-senha/forcar-alterar-senha.module').then((m) => m.ForcarAlterarSenhaModule),
            },
        ],
    },
    { path: 'not-found', loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundModule) },
    { path: '**', redirectTo: 'not-found', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
