import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Notify } from 'notiflix';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if ([401].includes(err.status) && (this.authenticationService.userValue || localStorage.getItem('accessToken'))) {
                    Notify.info('Sua sessão expirou, por favor faça login novamente.');
                    this.authenticationService.logout();
                }

                if ([403].includes(err.status) && (this.authenticationService.userValue || localStorage.getItem('accessToken'))) {
                    Notify.info('Você não tem permissão para executar esta função.');
                }

                return this.handleErrorDome(err.error);
            })
        );
    }

    handleErrorDome(err: any) {
        const errorMessage: string[] = [];
        if (typeof err.errors === 'object') {
            for (const key in err.errors) {
                if (err.errors[key]) {
                    errorMessage.push(err.errors[key]);
                }
            }
        }

        return throwError(() => ({
            ...err.error,
            message: errorMessage.join(' ') || err.detail || 'Ocorreu um erro desconhecido ao realizar requisição.',
        }));
    }
}
