import { AuthenticationService } from 'src/app/services/authentication/authentication.service';


export function appInitializer(authenticationService: AuthenticationService) {
  return () => new Promise(resolve => {
      authenticationService.refreshToken()
          .subscribe()
          .add(resolve as any);
  });
}
