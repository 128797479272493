import { Roles } from '../enums/roles.enum';

export const menuItens: IMenuItem[] = [
    { icon: 'home', label: 'Tela Inicial', route: '/', active: true, role: Roles.HOME_ACESSAR },
    {
        icon: 'reconciliation',
        label: 'Reprocessamento de Documentos',
        route: '/reprocessamento',
        active: true,
        role: Roles.REPROCESSAMENTO_ACESSAR,
    },
    { icon: 'form', label: 'Gerenciamento de Permissões', route: '/permissionamento', active: true, role: Roles.ACAO_ACESSAR },
    { icon: 'user', label: 'Gerenciamento de Usuários', route: '/usuarios', active: true, role: Roles.USUARIOS_ACESSAR },
    { icon: 'shop', label: 'Gerenciamento de Empresas', route: '/empresas', active: true, role: Roles.EMPRESAS_ACESSAR },
    { icon: 'mail', label: 'Gerenciamento de E-mails', route: '/emails', active: true, role: Roles.EMAILS_ACESSAR },
    {
        icon: 'apartment',
        label: 'Permissões Back-Office',
        route: '/permissionamento-backoffice',
        active: true,
        role: Roles.ACAOBACKOFFICE_ACESSAR,
    },
    {
        icon: 'appstore-add',
        label: 'Gerenciamento de Funcionalidades',
        route: '/funcionalidades',
        active: true,
        role: Roles.FUNCIONALIDADES_ACESSAR,
    },
];

interface IMenuItem {
    active: boolean;
    icon: string;
    label: string;
    route: string;
    role: string;
}
