import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { IUser } from '../interfaces/authentication/user.interface';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    public refreshTokenInProgress: boolean = false;
    public refreshTokenSubject: BehaviorSubject<null> = new BehaviorSubject<null>(null);

    constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const token = this.authenticationService.getToken();

        if (this.authenticationService.isLoggedIn()) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${token}` },
            });
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error && error.status === 401) {
                    if (!this.refreshTokenInProgress) {
                        this.refreshTokenInProgress = true;
                        this.refreshTokenSubject.next(null);
                        return this.authenticationService.refreshToken().pipe(
                            switchMap((data: IUser | null) => {
                                this.refreshTokenInProgress = false;
                                this.refreshTokenSubject.next(data!.refreshToken);
                                return next.handle(this.injectToken(request));
                            })
                        );
                    } else {
                        return this.refreshTokenSubject.pipe(
                            filter((result) => result !== null),
                            take(1),
                            switchMap(() => {
                                return next.handle(this.injectToken(request));
                            })
                        );
                    }
                } else {
                    throw error;
                }
            })
        );
    }

    injectToken(request: HttpRequest<unknown>) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.authenticationService.getToken()}`,
            },
        });
        return request;
    }
}
