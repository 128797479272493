import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const user = this.authenticationService.userValue;
        if (user && this.authenticationService.hasRole(route.data.role)) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}
